<template>
  <v-col cols="12" style="height: 100vh">
    <v-tabs v-model="tab" centered>
      <v-tab>{{ $t('userSettings.changePassword') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <ChangePassword />
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script>
import ChangePassword from './ChangePassword';

export default {
  name: 'UserSettings',
  components: {
    ChangePassword
  },
  data() {
    return {
      tab: null
    };
  }
};
</script>

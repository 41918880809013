









































































import IconTooltip from '@/components/IconTooltip.vue';
import { auth } from '@/plugins/firebase';
import { FormVue } from '@/components/forms/FormVue';
import { Component } from 'vue-property-decorator';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';

@Component({
  components: { IconTooltip }
})
export default class ChangePassword extends FormVue {
  oldPassword: any | null = '';
  password: any | null = '';
  show1 = false;
  passwordRepeat = '';
  resolver: any | null;
  loading = false;
  user: any = null;
  email = '';

  created() {
    this.loading = false;
    this.user = auth.currentUser;
    this.email = this.user ? this.user.email : '';
  }

  async changePassword() {
    if ((this.$refs.form as any).validate()) {
      const user = auth.currentUser as any;
      try {
        this.loading = true;
        this.email = user ? user.email : '';
        if (this.email) {
          signInWithEmailAndPassword(auth, this.email, this.oldPassword)
            .then(() => {
              updatePassword(user, this.password)
                .then(() => {
                  this.$showMessage(this.$t('userSettings.passwordChanged'));
                  this.reset();
                  this.$router.push({ name: 'Home' });
                })
                .catch(() => {
                  this.$showMessage(this.$t('userSettings.passwordNotChanged'));
                });
            })
            .catch(() => {
              this.$showMessage(this.$t('login.notSuccessful'));
            });
        }
      } catch (err) {
        console.log('Error', err);
      } finally {
        this.loading = false;
      }
    }
  }

  passwordConfirmation() {
    return this.password === this.passwordRepeat || this.$t('validation.passwordsMustMatch');
  }

  reset() {
    const refForm = this.$refs.form as any;
    if (refForm?.reset) {
      refForm.reset();
    }
  }
}
